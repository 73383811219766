import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export default defineComponent({
  props: {
    selected: {
      type: Object,
      required: true
    },
    practiceOptions: {
      type: Array,
      required: true
    },
    availablePracticeOptions: {
      type: Array,
      required: true
    },
    experienceOptions: {
      type: Array,
      required: true
    }
  },
  emits: ['update:practice', 'update:experience', 'delete'],
  setup: function setup(props) {
    var selectedPracticeOption = computed(function () {
      return props.availablePracticeOptions.find(function (option) {
        return option.id === props.selected.practiceId;
      });
    });
    var localPracticeOptions = computed(function () {
      return [selectedPracticeOption.value || {
        name: '選択してください',
        id: -1,
        isExperienceRequired: false
      }].concat(_toConsumableArray(props.practiceOptions));
    });
    var localExperienceOptions = computed(function () {
      return [{
        name: '選択してください',
        id: -1,
        isExperienceRequired: false
      }].concat(_toConsumableArray(props.experienceOptions));
    });
    return {
      localPracticeOptions: localPracticeOptions,
      localExperienceOptions: localExperienceOptions
    };
  }
});